import React from 'react';

import { PutinFooter } from '../components/PutinFooter';

import { ContentCard } from '../components/ContentCard';

import NewIcon from '../icons/sparkles.svg'

import logo from "../../static/assets/mlp logo.svg"

import { BasePage } from '../components/BasePage';
import { LocalPutinsLazy } from '../components/LocalPutinsLazy';

// tslint:disable-next-line:no-var-requires
require("./index.less");


// FIXME: THIS CODE IS ASS AND HACKY AND RUSHED BUT WHATEVER 
export class SharePage extends React.Component<{}, {}> {


    constructor(props) {
        super(props);
    }

    goHome(edit: string = null, json: boolean = false) {
      if(typeof window === 'undefined') return;
      window.open("/" + (edit != null ? (json ? "?data=" : "?edit=") + edit : "") , "_self")
    }


    public render() {

        const isSSR = typeof window === "undefined"



        const leftSide = [

          <div key="top bar" className="mlp-control-padded" style={{height:"40px", justifyContent:"left"}}>
            <button key="home button" style={{background: "transparent", border: "none" , width: "40px", height: "40px", flex: "1"}} onClick={()=>{this.goHome()}}> <img style={{height: "100%"}} src={logo} /></button>
            <button key="create new button" onClick={()=>{this.goHome()}} style={{maxWidth:"200px", fontWeight: "bold"}} className={"rainbow mlp-button"}> <NewIcon className="whiteFillAlways" height="18px" width="18px" /> Create New Putin </button>
          </div>,
          <ContentCard key="local" border={false} children={[
            { type: "Heading1", content:"MY LITTLE PUTINS" },
            { type: "Note", content:"These Little Putins are stored on your device." },
            { type: "Heading1", content:"" },
            <LocalPutinsLazy expanded={true} size={{x: 150, y: 150}} /> 
          ]} />

        ]

        return <BasePage key="base" title={"Gallery"} className="PageContentWider white" >
          {leftSide}
          <PutinFooter  key="footer" goHome={true} />
        </BasePage>
    }
}

export default SharePage;